<template>
  <div class="RoleAdd">
    <GlobalInfoBar title="角色权限" content="管理总平台账号访问的角色权限添加/编辑" />
    <div class="formRole">
      <div class="case-title">
        <div class="icon_title">
          <div>
            <img class="case-title_icon" src="@/assets/images/imgAll/icon_jsqx_@2x.png" alt />
          </div>
          <div class="case-title_max">{{ this.titleDetail }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="formdata">
        <div class="formitem">
          <div><span style="color:red;margin:5px">*</span>角色名称：</div>
          <div>
            <el-input v-model="roleName" placeholder="请输入名称，可按职位命名（限制输入6字符内）" maxlength="6"></el-input>
          </div>
        </div>
        <div class="formitem">
          <div><span style="color:white;margin:5px">*</span>角色说明：</div>
          <div>
            <el-input v-model="roleInfo" placeholder="非必填项（限制输入50字符内）" maxlength="50"></el-input>
          </div>
        </div>
        <div class="radiobtn">
          <div class="faultBtn">
            <div :class="auditStatus === '00' ? 'active' : 'none'" @click="btnClick('00')">
              菜单权限
            </div>
            <div :class="auditStatus === '01' ? 'active' : 'none'" @click="btnClick('01')">
              操作权限
            </div>
          </div>
        </div>
        <div class="formitemTree">
          <div class="title_Tree" v-if="auditStatus === '01'">
            <div class="cheout" v-for="(item, index) in operatAuthorization" :key="index">
              <span>{{ item.pageName }}</span>
              <el-checkbox-group v-model="checkedOpt" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="optionitem in item.buttonList" :label="optionitem.buttonName" :key="optionitem.id">{{
                  optionitem.buttonName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="treeForm" v-else>
            <div class="checkForm">
              <el-tree ref="tree" default-expand-all :data="TreeData" :props="props" :node-key="props.value"
                @check-change="getCheckedNodes" :default-checked-keys="treeListIds" show-checkbox>
              </el-tree>
            </div>
          </div>
        </div>
        <div class="subBtn">
          <el-button @click="handerClose()">取消</el-button>
          <el-button @click="handerClick()" type="primary">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _api from "@/utils/request";
export default {
  data() {
    return {
      titleDetail: "",
      checkedOpt: [],
      treeListIds: [],
      TreeData: [],
      props: {
        value: 'id',
        children: 'children',
        label: 'resourceName'
      },
      roleInfo: "",
      roleName: "",
      treeIds: [],
      roleId: "",
      auditStatus: "00",
      buttonIds: [],
      operatAuthorization: []
    };
  },
  created() {

  },
  computed: {
    // RoleinfoData() {
    //   return this.$store.state.tagsView.RoleinfoData
    // }
  },
  mounted() {
    if (this.$route.query.type) {
      //编辑
      this.getalllist(this.$route.query.type)
    } else {
      this.allRes()
      this.allButton()
      this.titleDetail = '新增角色'
    }
  },
  methods: {
    handleCheckedCitiesChange(value) {
      console.log(value);

    },
    //所有列表
    getalllist(id) {
      _api.RoleInfoRes({ id }).then(res => {
        if (res.code === 1) {
          this.RoleinfoData = res.data
          this.checkedOpt = []
          this.titleDetail = '编辑角色'
          this.roleInfo = this.RoleinfoData.sysRole.roleInfo
          this.roleName = this.RoleinfoData.sysRole.roleName
          this.roleId = this.RoleinfoData.sysRole.id
          this.TreeData = this.RoleinfoData.allRes
          this.operatAuthorization = this.RoleinfoData.buttonViews
          this.operatAuthorization[0].buttonList.forEach(item => {
            if (item.selected) {
              this.checkedOpt.push(item.buttonName)
            }
          })

          this.$store.commit('tagsView/MENU_LIST', this.RoleinfoData.allRes)

          const arr = this.RoleinfoData.selectedRes
          arr.forEach(item => {
            if (item.resourceGrade != 1 || item.resourceName == '首页') {
              this.treeListIds.push(item.id)
            }
            this.treeIds.push(item.id)
          })
          console.log(this.RoleinfoData)
        }
      })
    },
    //状态切换
    btnClick(type) {
      this.auditStatus = type
    },
    //tree数据列表
    allRes() {
      _api.allRes().then(res => {
        this.TreeData = res.data
        this.$store.commit('tagsView/MENU_LIST', res.data)
      })
    },
      //tree数据列表
      allButton() {
      _api.allButton().then(res => {
        this.operatAuthorization = res.data
          this.operatAuthorization[0].buttonList.forEach(item => {
            if (item.selected) {
              this.checkedOpt.push(item.buttonName)
            }
          })

      })
    },
    // tree点击
    getCheckedNodes() {
      let res = this.$refs.tree.getCheckedKeys()
      let result = this.$refs.tree.getHalfCheckedKeys()
      // console.log(res)
      // let arr = []
      // res.forEach((item) => {
      //   arr.push(item.id)
      // })
      this.treeIds = res.concat(result)
      console.log(this.treeIds)
    },
    // 提交
    handerClick() {
      this.buttonIds = []
      this.checkedOpt.forEach(resitem => {
        this.operatAuthorization[0].buttonList.forEach(item => {
          if (item.buttonName == resitem) {
            this.buttonIds.push(item.id)
          }
        })
      })
      const params = {
        arr: this.treeIds,
        roleInfo: this.roleInfo,
        buttonIds: this.buttonIds,
        roleName: this.roleName,
        id: this.roleId
      }
      if (this.roleName === '') {
        this.$message.error('请输入角色')
      } else if (this.treeIds == '') {
        this.$message.error('请选择权限')
      } else {
        _api.RoleSaveUser(params).then(res => {
          if (res.code === 1) {
            this.$message.success("操作成功")
            this.$router.push({ path: "/systemManage/RolePurview" })
            let roleId = localStorage.getItem('roleId')
            let flag = this.treeIds.indexOf(13)
            if (this.roleId == roleId) {
              if (flag == '-1') {
                this.$router.push({ path: "/dashboard" })
              }
              window.location.reload()
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    // 返回
    handerClose() {
      this.$router.go(-1)
    },
  }
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.RoleAdd {
  .radiobtn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    margin-left: 30px;
    // min-width: 500px;
    height: 34px;
    background: #F9FBFD;
    border: 1px solid #C1D1FF;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      // width: 50%;
      height: 14px;
      padding: 0 15px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981FF;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      // width: 50%;
      padding: 0 15px;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  .formRole {
    width: 100%;
    background-color: white;
    border-radius: 20px;

    .line {
      border: 1px solid #edeffd;
    }

    .case-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      .icon_title {
        display: flex;

        .case-title_icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }

        .case-title_max {
          display: inline-block;
          width: 100px;
          height: 17px;
          font-size: 18px;
          font-family: FZLanTingHei-B-GBK;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
        }
      }
    }

    .formitemTree {
      margin: auto;
      width: 95%;
      display: flex;
      margin: 20px;
      justify-content: left;

      .title_Tree {
        width: 95%;
        border: 1px solid #edeffd;
        min-height: 420px;
        margin-left: 10px;
        padding-bottom: 20px;
      }

      .cheout {
        padding: 20px 15px;
        display: flex;
        align-items: center;

        >span {
          font-size: 14px;
          margin-right: 20px;
        }
      }

      .treeForm {
        width: 95%;
        border: 1px solid #edeffd;

        margin-left: 10px;
        padding-bottom: 20px;
      }
    }

    .formdata {
      margin: auto;
      width: 95%;

      .formitem {
        display: flex;
        margin: 20px;
        justify-content: left;

        div:first-child {
          width: 100px;
          font-size: 14px;
          font-family: FZLanTingHei-M-GBK;
          font-weight: 400;
          color: #666666;
          height: 40px;
          line-height: 40px;
        }

        div:last-child {
          width: 95%;
        }
      }
    }

    .subBtn {
      margin-left: 7%;
      padding: 50px 0;
    }
  }
}
</style>